<template>
  <div>
    
   <h1>ご注文履歴・もう一度注文</h1>


    <login v-if="!logined" v-model="logined" />

<div v-else>

<div class="lead">
  過去に行ったご注文のご確認ができます。<br>また、過去に行ったご注文と同じ内容を使用して、ご注文にお進み頂くことができます。<br>

  <br>
  ご注意：すでに会員登録済みでも、ご注文時にログインせずに注文した場合は、以下に履歴が出ませんのでご了承ください。
  <br>会員としてログインをせずにご注文された場合は
<router-link @click.native="scrollToTop" class="" to="/status">[ こちら ]</router-link>のページよりご確認ください<br>
  <br>ご注文完了メールが届いていれば、ご注文は正常に完了しております。</div>

<div class="empty" v-if="!myorder.length">
  まだご注文履歴がありません
</div>

<ul>
  <li v-for="v,i in myorder" :key="i">
    
    <p class="date">ご注文日時：{{v.page_newdate}} ／ 合計：{{v.fields.prices_fix.total_amount}}円</p>
    ご注文番号：{{v.fields.orderno.value}}<br>
    ご注文数：{{v.fields.items.subfields.length}}<br>
    現在の状況：{{v.fields.order_status.textvalue}}<br>
<p class="bns">
<router-link @click.native="scrollToTop" class="bn" :to="'/status/'+v.fields.ordertoken.value">[ ご注文状況を表示 ]</router-link>
<span class="bn" @click="sameorder(i)">もう一度同じ内容で申込を行う</span>
<!--<span class="bn" :class="{disabled:v.update_expire}" @click="updateorder(i)">内容を変更する</span>-->
 </p> </li>
</ul>

<partmenu />
</div>


    <dialogalert
      v-model="visible_reorder"
      title="同じ内容でもう一度ご注文しますか？"
      :info="reorder_info"
      button_ok="もう一度注文(お申込み画面へ)"
      button_cancel="キャンセル"
      :ok_fn="sameorder_ok_fn"
    />

    
    <dialogalert
      v-model="visible_updateorder"
      title="注文内容を変更しますか？"
      :info="reorder_info"
      button_ok="注文内容の変更画面へ"
      button_cancel="キャンセル"
      :ok_fn="updateorder_ok_fn"
    />



    <dialogalert
      v-model="visible_alert"
      :title="alert_title"
      :info="alert_info"
      button_ok="OK"
    />

    

<fullloader :loading="fullloading" />
  </div>
</template>

<script>
import partmenu from '@/components/part_menu.vue'; // @ is an alias to /src

export default  {
  data:function(){return {
    myorder:[],
    visible_reorder:false,
    visible_updateorder:false,
    visible_alert:false,
    alert_title:"",
    alert_info:"",
    reorder_info:"",
    myorder_idx:0
  }},
  components: {
    partmenu,
  },
  beforeCreate:function(){
    
    //window.checkalways_includes="statuses";
    //console.log("before order history init",window.checkalways_includes)
  },
  created:function(){
    window.checkalways_includes="statuses";
    console.log("order history init",window.checkalways_includes)
    this.loaded_countmax = 1;
      this.fullloading=true;  
    this.$parent.middle = 2; 
    this.login("mypage").then((data)=>{
      this.loaded_countup();
      this.myorder=data.myorder;
      console.log(data);
      for(var i=0;i<this.myorder.length;i++){//console.log("myorder:"+i,this.myorder[i])
        this.myorder[i].fields.prices_fix=JSON.parse(this.myorder[i].fields.prices.value)
      }
    }).catch((data) => {
          this.loaded_countup();
      });

  },
  methods:{
    sameorder:function(i){
      this.myorder_idx=i;
      this.visible_reorder=true;
      //let record = this.getSession("record");
      //console.log('this.myorder',this.myorder[i],'this.record',record)
      this.reorder_info="このご注文と同じ内容をご注文フォームに自動入力しますか？<br>お届け先、呼びかけ名などがあらかじめ入力された状態でご注文にお進み頂けます。<hr><strong>※すでにカートに入っている内容は全てクリアされ、自動入力された内容に置き換えられます。よろしいですか？</strong>";
    },
    updateorder:function(i){
      if(this.myorder[i].update_expire){
        this.visible_alert=true;
        this.alert_title="ご注文を変更できません";
        this.alert_info="ご指定のご注文は変更することができません。";
        return;
      };

      this.myorder_idx=i;
      this.visible_updateorder=true;
      //let record = this.getSession("record");
      //console.log('this.myorder',this.myorder[i],'this.record',record)
      this.reorder_info="このご注文の内容を変更しますか？";
    },
    
    updateorder_ok_fn:function(){
      let i=this.myorder_idx;
      this.$router.push("/mypage/update/"+this.myorder[i].ID);
      //window.location.href="/mypage/update/"+this.myorder[i].ID;
      return;
      let item_label=this.myorder[i].item_label;
      //item_label
      //フォーマットは、this.recordが正しい。
      this.currentLabel=item_label+"_update_"+this.myorder[i].ID;
      console.log("this.currentLabel",this.currentLabel,this.myorder[i])
      
      //let includelist=["items","mydeliver"];
      this.build_record_copy(this.myorder[i],false);
      this.record.ID=this.myorder[i].ID;
      //let pricedata=JSON.parse(this.myorder[i].fields.prices.value);
      //注文時のデータを
      //this.$set(this.record,"price_ordered",pricedata.total_amount)

      console.log("record merged",this.record);
      window.location.href="/item/"+item_label+"/update/"+this.myorder[i].ID;
    },
    
    sameorder_ok_fn:function(){
      let item_label=this.conf.statuses.available_item_label;
      console.log("sameorder - item_label",item_label);
      if(!item_label){alert("現在、お申し込みできる商品がありません。");return;}
      let i=this.myorder_idx;
      //フォーマットは、this.recordが正しい。
      let includelist=["items","mydeliver"];
      this.currentLabel=item_label;

      this.build_record_copy(this.myorder[i],includelist,this.conf.statuses.available_item_ID);

      console.log("sameorder - record merged",this.record);
      window.location.href="/item/"+item_label+"/";
    },

    //与えられた注文データを使用して、指定されたフィールドをコピーして作成する
    build_record_copy(orderdata,includelist,available_item_ID){
       console.log("リセット時0")
       console.log(this.record.fields.city.value)
      this.clearAllCart_exec();
//return;
      //let record = this.getSession("record");
       console.log("リセット時1")
       console.log(this.record.fields.city.value)
      // if (!record) {
         let record=this.record;
       //}
       console.log("リセット時2")
       console.log(record.fields.city.value)
      //this.clearAllCart();
      //指定した注文データを、myorderからコピーして挿入する
      for(let n in record.fields){
        if(includelist && includelist.indexOf(n)==-1)continue;
        if(typeof orderdata.fields[n]=="undefined")continue;
          //  console.log("対象：",n);
        //サブフィールド以外はそのまま代入
        if(typeof record.fields[n].value=="string" && typeof orderdata.fields[n].value=="string"){
            record.fields[n].value=orderdata.fields[n].value;
            //注文時のデータを特別に退避する
            if(!includelist){
              record.fields[n].value_ordered=orderdata.fields[n].value;
              record.fields[n].textvalue_ordered=orderdata.fields[n].textvalue;
            }
        
        }else if(typeof record.fields[n].subfields!="undefined" && typeof orderdata.fields[n]!="undefined"){
          //サブフィールドの子を予めディープコピー
          let subnames=[];
          for(let n3 in record.fields[n].subfields[0]){
            subnames.push(n3);
          }
          record.fields[n].subfields=[];
          //実際に読み込むサブフィールドの数分追加
          for(let n2=0; n2 < orderdata.fields[n].subfields.length;n2++){
            let subfields={}
            for(let n4=0; n4 < subnames.length;n4++){
              //console.log(2,subnames[n4],subfields,orderdata.fields[n].subfields[n2],orderdata.fields[n])
              if(typeof orderdata.fields[n].subfields[n2][subnames[n4]]=="undefined")continue;
              //console.log(3,orderdata.fields[n].subfields[n2][subnames[n4]].value)
              if(n=="items" && subnames[n4]=="item"){
                subfields[subnames[n4]]={value:available_item_ID};
              }else{
                subfields[subnames[n4]]={value:orderdata.fields[n].subfields[n2][subnames[n4]].value};
              }
              //注文時のデータを特別に退避する
              if(!includelist){
                subfields[subnames[n4]].value_ordered=orderdata.fields[n].subfields[n2][subnames[n4]].value;
                subfields[subnames[n4]].textvalue_ordered=orderdata.fields[n].subfields[n2][subnames[n4]].textvalue;
                }
              //console.log("追加した：",subnames[n4],orderdata.fields[n].subfields[n2][subnames[n4]].value);
            }
            record.fields[n].subfields.push(subfields);
          }
        }
      }
        this.record=record;
    }
  }
}
</script>

<style scoped lang="scss">

$pc: 1024px; // PC
$tab: 680px; // タブレット
$sp: 480px; // スマホ
@mixin pc {
    @media (max-width: ($pc)) {
        @content;
    }
}

@mixin tab {
    @media (max-width: ($tab)) {
        @content;
    }
}

@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}


.empty{
  padding: 4em 0;
}
.date{
  border-bottom: solid 1px #ccc;
  padding-bottom: 1em;
  margin-bottom: 1em;
}
.bns{
  margin-top: 1em;
  display: flex;
  width: 90%;
  max-width:35em;
  flex-direction: row;
  justify-content:space-between;
  margin: 0 auto;
  margin-top: 1em;
  flex-direction: column;
}
.bn{
  border: solid 2px #a52727;
  text-decoration: none;
  padding: .8em 2em;
  color: #a52727;
  background: url("../../assets/images/mypage7.png") no-repeat right 1em center / 1em;
  padding-right: 3em;
  cursor: pointer;
  border-radius: .3em;
  margin-bottom:.3em;
		@include sp{
		}
}

.bn.disabled{
  opacity: .3;
  background-color: rgb(212, 212, 212);
}
.bn:hover{
  background-color: rgb(255, 255, 255);
}
.lead{
  background: #FFE2DC;
  border-radius: .2em;
  margin-bottom: 1em;
  padding: .8em;
  font-size: .8em;
}
h1{
  margin-top: 1em;
}
ul{
  list-style: none;
  li{
    box-shadow: .2em .2em .2em #ccc;
    padding:1em;
    border:solid 1px #ccc;
    border-radius: .5em;
    margin-bottom: 1em;
    background: #EDEDED;
  }
}
</style>